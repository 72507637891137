@use "../config/" as *;
.waf-detail {
    &.waf-component {
        @extend %p-0;
    }
    > .layout-wrapper {
        max-width: 100%;
        > .waf {
            &-head {
                padding: 14.4rem var(--space-4) 11.2rem;
                border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
                height: 60rem;
                @extend %relative;
                @extend %hidden;
                @extend %mx-2-neg;
                @extend %flex-column-c-n;
                &::after {
                    content: "";
                    @include bgImg("cssimages/pattern/details-bg-mob.png", center, cover);
                    @include position-combo(inset);
                    @extend %zindex-pattern;
                }
                &::before {
                    content: "";
                    @include bgImg('svg/vector-gradient.svg', center, contain);
                    @include position(-3rem, null, null, 68.5%);
                    @extend %h-100;
                    @extend %w-100;
                }
                > .title {
                    @include truncate-text(3);
                    @extend %title-40;
                    @extend %neutral-0;
                    @extend %relative;
                    @extend %zindex-hero-content;
                    @extend %my-2;
                }
                > p {
                    @extend %mb-0;
                }
                .img-box {
                    @include position-combo(inset);
                    @extend %common-gradient;
                    @extend %h-100;
                    &:has(img[src=""]:empty) {
                        background-color: unset;
                    }
                }
                .meta {
                    @extend %flex-n-c;
                    @extend %neutral-0;
                    @extend %body-m;
                    @extend %relative;
                    @extend %zindex-hero-content;
                    > .text {
                        @extend %neutral-0;
                        @extend %mb-0;
                    }
                    &-date {
                        @extend %pr-1;
                    }
                    &-author {
                        @extend %gap-4;
                        &:not(:empty) {
                            &::after {
                                content: '';
                                width: 0.5rem;
                                height: .5rem;
                                @include position(null, -1rem);
                                @extend %bg-neutral-0;
                                @extend %d-block;
                                @extend %circle-radius;
                            }
                            + .meta-date {
                                @extend %pl-4;
                            }
                        }
                    }
                    img {
                        width: 2rem;
                        aspect-ratio: 1/1;
                    }
                    &-lang {
                        transform: translateY(2.6rem);
                        font-weight: 700;
                        @extend %ml-auto;
                        @extend %flex-n-c;
                        @extend %gap-2;
                        &::after {
                            font-weight: 400;
                            @include icon(globe, 20);
                        }
                    }
                }
            }
            &-body {
                @extend %mt-18-neg;
                @extend %neutral-100;
                @extend %relative;
                @extend %px-2;
            }
        }
    }
    &.waf-gallery-page,
    &.waf-video-page {
        > .layout-wrapper {
            > .waf-head {
                justify-content: center;
                height: 43rem;
            }
        }
    }
    &.waf-story-page {
        > .layout-wrapper {
            > .waf-head {
                height: 50rem;
            }
        }
        .waf-listing {
            .article-meta {
                line-height: 1;
                @extend %font-12-pr;
            }
        }
    }
    @extend %article-tagline;
    .title,
    .description,
    .article-description {
        @extend %title-50;
    }
    .text {
        @extend %body-l;
    }
    .title,
    .text,
    .sub-title,
    p {
        word-wrap: break-word;
        line-height: 1.4;
        @extend %body-l;
        @extend %mb-10;
    }
    .article {
        &-meta {
            @extend %flex;
            @extend %flex-wrap;
            @extend %relative;
            @extend %zindex-content;
        }
        &-tagline {
            @extend %relative;
            @extend %zindex-content;
            .text {
                margin-bottom: 0;
                text-transform: capitalize;
            }
        }
        &-media {
            @extend %w-100;
            @extend %text-center;
        }
        &-main-content,
        &-tags,
        &-eventinfo-wrapper,
        &-result-wrapper,
        &-news-wrapper {
            @extend %bg-neutral-0;
            @extend %p-6;
            @extend %mb-6;
            @extend %border-radius-m;
        }
        &-eventinfo-wrapper {
            .meta {
                &-category {
                    white-space: unset;
                    line-height: 1.2;
                }
            }
            .card-title {
                line-height: 2.2rem;
                font-size: 1.4rem;
                white-space: unset;
                // a {
                //     color: var(--neutral-100);
                // }
            }
            @extend %event-item;
            .content-title {
                @extend %mb-0;
            }
            .card-item {
                display: flex;
                padding-inline: 0;
                border-block: 0;
                grid-template-columns: 11rem calc(100% - 11rem);
                &:not(:last-child) {
                    @include border(1, neutral-20, 10, bottom);
                }
            }
            .card-right {
                margin-left: var(--space-0);
            }
            .card-left {
                padding-right: var(--space-4);
            }
            .card-date {
                width: max-content;
            }
        }
        &-gallery-list {
            @include grid(2, var(--space-4));
        }
        &-gallery-item {
            @extend %relative;
            &.img-16by9 {
                grid-column: 1/span 2;
            }
            img {
                object-fit: cover;
            }
        }
        &-content-container {
            ul {
                list-style-type: disc;
            }
            li {
                @extend %mb-2;
            }
            ol {
                list-style-type: decimal;
            }
            :is(ul, ol) {
                @extend %pl-4;
                @extend %mb-2;
            }
            // a {
            //     @extend %primary-50;
            //     &:hover {
            //         text-decoration: underline;
            //     }
            // }
            strong,
            em {
                font-weight: 700;
                @extend %neutral-100;
            }
            iframe,
            video {
                min-height: 17.5rem;
                @extend %w-100;
                @extend %mb-6;
                @extend %border-radius-s;
            }
        }
        &-bottom-content {
            .head-tab {
                @extend %d-none;
            }
            @extend %tabs-underline;
        }
        &-featured-media {
            &.item-type-video,
            &.item-type-photo {
                position: static;
            }
        }
        &-news-wrapper {
            .title {
                @extend %mb-6;
                @extend %title-50;
            }
        }
    }
    .tag {
        &s-list {
            flex-wrap: wrap;
            @extend %flex;
            @extend %gap-2;
        }
        &-item {
            @extend %tag-outline;
        }
    }
    .btn {
        &-more {
            @extend %border-radius-s;
            @extend %flex-c-c;
            @include position-combo(inset);
        }
    }
    .waf-listing {
        @include article-listing(horizontal);
        .btn-link {
            @extend %d-none;
        }
        .img-box {
            aspect-ratio: 1/1;
        }
        .swiper-button-prev,
        .swiper-button-next {
            @extend %d-none;
        }
    }
    .waf-overlay-list {
        @include article-listing(overlay);
        .title,
        .tabs {
            @extend %mb-6;
        }
        .title {
            @extend %title-50;
        }
        .img-box {
            aspect-ratio: 7/8;
        }
        .article-list {
            grid-auto-flow: column;
            grid-auto-columns: calc(100% - var(--space-6));
            grid-template-columns: unset;
            overflow-x: auto;
        }
    }
    .accordion {
        &-header {
            border-bottom: 0.1rem solid var(--neutral-20);
            @extend %flex-sb-c;
            @extend %title-60;
            @extend %py-4;
            &[aria-expanded=true] {
                border-color: var(--primary-50);
            }
            .btn-text {
                @extend %title-60;
            }
        }
        &-action {
            @extend %mt-4;
            .btn-link {
                @extend %btn-default;
            }
        }
    }
    .table {
        @extend %hidden;
        &-row {
            border-bottom: 0.1rem solid var(--neutral-20);
            justify-content: flex-start;
            @extend %py-4;
        }
        &-body {
            @extend %x-auto;
        }
        &-data {
            justify-content: flex-start;
            @extend %px-2;
            &-wrap {
                @extend %flex-n-c;
                @extend %gap-2;
            }
            .text {
                @extend %mb-0;
                @extend %text-m;
            }
        }
        .pos {
            flex: 0;
        }
        .name {
            flex-basis: 15rem;
            @extend %text-left;
        }
        .number {
            flex-basis: 5rem;
        }
        .place {
            flex-basis: 8rem;
            img {
                width: 2.4rem;
                height: 2.4rem;
                aspect-ratio: 1/1;
                @extend %circle-radius;
            }
            .text {
                @extend %text-m;
            }
        }
    }
    .btn-loadmore {
        @extend %flex-c-c;
        @extend %w-100;
        @extend %mt-10;
        .btn-text {
            @extend %btn-fill;
        }
    }
}
//allowed by Pranav Sir
.waf-story-page {
    .article {
        &-main-content {
            img {
                width: 100% !important;
                height: auto !important;
            }
        }
        &-featured-media {
            img {
                object-fit: cover;
                object-position: center;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-detail {
        > .layout-wrapper {
            > .waf {
                &-head {
                    .meta {
                        &-lang {
                            transform: translateY(0);
                        }
                    }
                    // > .title {
                    //     height: 20.8rem;
                    // }
                }
            }
        }
        &.waf-story-page {
            .waf-listing {
                .article-meta {
                    font-size: 1.4rem;
                }
            }
        }
        .waf-overlay-list {
            margin-block: var(--space-5) var(--space-20);
            .layout-wrapper {
                max-width: 100%;
            }
            .article-list {
                grid-auto-flow: unset;
                grid-auto-columns: unset;
                grid-template-columns: repeat(3, 1fr);
                overflow-x: unset;
            }
        }
        .table-data {
            flex: 1;
        }
        .article {
            &-eventinfo-wrapper {
                .card-title {
                    line-height: 2.8rem;
                    font-size: 2rem;
                    margin-block: var(--space-4) var(--space-0);
                }
                .meta {
                    font-size: 1.4rem;
                }
            }
            &-content-container {
                iframe,
                video {
                    min-height: 39rem;
                    border-radius: 1.6rem;
                }
            }
            &-news-wrapper {
                .img-box {
                    aspect-ratio: 21/20;
                }
                .article-title {
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                }
                .title {
                    margin-bottom: var(--space-4);
                    font-size: 2.4rem;
                }
            }
        }
        .btn-loadmore {
            margin-top: var(--space-12);
        }
    }
}
@include mq(col-lg) {
    .waf-detail {
        > .layout-wrapper {
            > .waf {
                &-head {
                    padding-block: 10rem 0;
                    // height: 60rem;
                    padding-inline: var(--container-white-space);
                    &::before {
                        transform: translate(0, -30%);
                        left: 28%;
                        height: inherit;
                    }
                    &::after {
                        @include bgImg("cssimages/pattern/details-bg-web.png");
                    }
                    & > .title {
                        font-size: 4rem;
                        width: 60%;
                        line-height: 1.3;
                    }
                    .title, .social-share {
                        max-width: var(--lg-container-max-width);
                        margin-inline: auto;
                        width: 100%;
                        padding-inline: var(--space-10);
                    }
                    .article {
                        &-tagline, &-description, &-meta {
                            max-width: var(--lg-container-max-width);
                            margin-inline: auto;
                            width: 100%;
                            padding-inline: var(--space-10);
                        }
                    }
                }
                &-body {
                    max-width: var(--lg-container-max-width);
                    margin-inline: auto;
                    margin-top: var(--space-12-neg);
                }
            }
        }
        &.waf-gallery-page,
        &.waf-video-page {
            > .layout-wrapper {
                > .waf-head {
                    justify-content: center;
                    height: 45rem;
                }
            }
        }
        &.waf-story-page {
            > .layout-wrapper {
                > .waf-head {
                    height: 60rem;
                }
            }
        }
        .title {
            font-size: 3.2rem;
        }
        .article {
            &-content-container {
                display: grid;
                gap: var(--space-6);
                grid-template-columns: calc(100% - 47.4rem) 45rem;
            }
            &-main-content {
                padding: 5.6rem;
                .title,
                .text,
                .sub-title,
                .table,
                ol,
                ul {
                    padding-inline: var(--space-10);
                }
            }
            &-swiper {
                position: relative;
                padding-top: 10rem;
                padding-inline: 0;
            }
        }
        .waf-overlay-list {
            .title {
                font-size: 2.4rem;
            }
            .article-list {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}